
import { defineComponent, ref, computed, reactive, watch, onMounted } from 'vue'
import InputElement from '@/components/UI/Input.vue'
import Button from '@/components/UI/Button.vue'
import Dropdown from '@/components/UI/Dropdown.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/store'
import { AuthActionTypes } from '@/store/auth/actions'
import { taxSettingsActionTypes } from '@/store/taxSettings/actions'
import Loader from '@/components/UI/Loader.vue'
import InfoPopup from '@/components/UI/InfoPopup.vue'
import rolesMapping from '@/composables/rolesMapping'
import _ from 'lodash'
export default defineComponent({
  name: 'Driver Account Details',
  setup() {
    const store = useStore()
    const { locale, t } = useI18n({ useScope: 'global' })
    const incomeTaxPercentages = ref([])
    const selectedIncomeTaxPercentage = ref(`${t('_generalTerms.pleaseSelect')}`)
    const useOfCarOptions = ref([
      { label: t('_generalTerms.yes'), value: true },
      { label: t('_generalTerms.no'), value: false }
    ])
    const privateUserMessageVisible = ref(false)
    const taxMessageVisible = ref(false)
    const saveButtonStatus = ref(false)
    const user = computed(() => store.state.auth.currentUser)
    const roles = computed(() => store.state.auth.currentUser.roles)
    const getTaxSettingsByYear = computed(() => store.state.taxSettings.getTaxSettingsByYear)
    const adminClientRole = computed(() => !roles.value.includes('ROLE_EMPLOYEE'))
    const loader = computed(() => store.state.auth.loader)
    const showPopup = ref(false)
    const updatePasswordErrors = computed(() => store.state.auth.passwordErrors)
    const passwordErrors = reactive({
      passwordRegex: false,
      confirmPasswordSame: false,
      emptyPassword: false,
      emptyNewPassword: false,
      emptyConfirmPassword: false,
      invalidPassword: false
    })
    const privateUse = ref(user.value.privateUse)
    const incomeTaxRangeId = ref()
    const updateIncomeButton = () => {
      if (selectedIncomeTaxPercentage.value !== `${t('_generalTerms.pleaseSelect')}`) {
        saveButtonStatus.value = true
        if (
          (incomeTaxRangeId.value && !user.value.incomeTax) ||
          (user.value.incomeTax && incomeTaxRangeId.value !== user.value.incomeTax.id) ||
          privateUse.value !== user.value.privateUse
        ) {
          saveButtonStatus.value = true
        } else {
          saveButtonStatus.value = false
        }
      }
    }
    const updateUserOptions = async () => {
      let newObj = {}
      let incomeTaxObj = {}
      let privateUseReq = false
      let incomeTaxReq = false
      if (!incomeTaxRangeId.value) {
        // private use yes without income
        taxMessageVisible.value = true
      } else if (incomeTaxRangeId.value) {
        // income with either yes or no both private use
        if (privateUse.value !== user.value.privateUse) {
          privateUseReq = true
        }
        if (incomeTaxRangeId.value) {
          incomeTaxReq = true
          getTaxSettingsByYear.value.incomeTaxSettings.map((item, index) => {
            if (item.id == incomeTaxRangeId.value) {
              incomeTaxObj = Object.assign({}, incomeTaxObj, getTaxSettingsByYear.value.incomeTaxSettings[index])
            }
          })
        }
        if (incomeTaxReq || privateUseReq) {
          newObj = Object.assign({}, user.value, {
            incomeTax: incomeTaxObj,
            leaseValidUntil:
              user.value.leaseValidUntil && user.value.leaseValidUntil ? user.value.leaseValidUntil : null,
            carPolicy: user.value.carPolicy ? user.value.carPolicy.id?.toString() : null,
            leaseCategory: user.value.leaseCategory ? user.value.leaseCategory.id.toString() : null,
            privateUse: privateUse.value
          })
        }
      } else if (!privateUse.value) {
        // only for no private use
        if (privateUse.value !== user.value.privateUse) {
          newObj = Object.assign({}, user.value, {
            leaseValidUntil:
              user.value.leaseValidUntil && user.value.leaseValidUntil ? user.value.leaseValidUntil : null,
            carPolicy: user.value.carPolicy ? user.value.carPolicy.id?.toString() : null,
            leaseCategory: user.value.leaseCategory ? user.value.leaseCategory.id.toString() : null,
            privateUse: privateUse.value
          })
        }
      }
      if (Object.keys(newObj).length > 0) {
        newObj['roles'] = rolesMapping(user.value.roles)
        delete newObj['company']
        await store.dispatch(AuthActionTypes.UPDATE_USER, {
          companyId: user.value.company.id,
          userId: user.value.id,
          body: newObj
        })
        if (incomeTaxRangeId.value) {
          incomeTaxPercentages.value.forEach((item) => {
            if (item.value == incomeTaxRangeId.value) {
              selectedIncomeTaxPercentage.value = item.label
            }
          })
        }
        privateUserMessageVisible.value = true
        saveButtonStatus.value = false
      } else {
        privateUserMessageVisible.value = false
      }
      store.dispatch(AuthActionTypes.GET_CURRENT_USER_DETAILS)
    }
    const selectUseOfCarOption = (payload) => {
      privateUse.value = payload.value
      updateIncomeButton()
    }
    const selectPercentage = (payload) => {
      incomeTaxPercentages.value.forEach((item) => {
        if (item.value == payload.value) {
          selectedIncomeTaxPercentage.value = item.label
          taxMessageVisible.value = false
        }
      })
      incomeTaxRangeId.value = payload.value
      updateIncomeButton()
    }
    const passwordFields = ref({
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    })
    const updateExistingPassword = (value) => {
      passwordFields.value['oldPassword'] = value
    }
    const updateNewPassword = (value) => {
      passwordFields.value['newPassword'] = value
    }
    const updateRepeatNewPassword = (value) => {
      passwordFields.value['confirmPassword'] = value
    }

    const closePopup = () => {
      showPopup.value = !showPopup.value
    }

    const updatePassword = async () => {
      const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
      passwordErrors.passwordRegex = false
      passwordErrors.confirmPasswordSame = false
      passwordErrors.emptyPassword = false
      passwordErrors.emptyConfirmPassword = false
      passwordErrors.emptyNewPassword = false
      passwordErrors.invalidPassword = false

      if (passwordFields.value.oldPassword === '') {
        passwordErrors.emptyPassword = true
      }
      if (passwordFields.value.newPassword === '') {
        passwordErrors.emptyNewPassword = true
      }
      if (passwordFields.value.confirmPassword === '') {
        passwordErrors.emptyConfirmPassword = true
      }
      if (passwordFields.value.newPassword !== passwordFields.value.confirmPassword) {
        passwordErrors.confirmPasswordSame = true
      }
      if (!regex.test(passwordFields.value.newPassword)) {
        passwordErrors.invalidPassword = true
      }
      if (Object.values(passwordErrors).every((v) => v === false)) {
        const res = await store.dispatch(AuthActionTypes.UPDATE_PASSWORD, {
          id: +user.value.id,
          body: passwordFields.value
        })
        if (res.status === 'success') {
          showPopup.value = true
        }
      }
    }
    const getIncometaxPercentage = () => {
      if (getTaxSettingsByYear.value.incomeTaxSettings.length) {
        getTaxSettingsByYear.value.incomeTaxSettings.map((item, index) => {
          let showLabel = ''
          if (getTaxSettingsByYear.value.incomeTaxSettings[index + 1]) {
            showLabel =
              '€ ' + item.fromValue + ' - € ' + (getTaxSettingsByYear.value.incomeTaxSettings[index + 1].fromValue - 1)
          } else {
            showLabel = '> € ' + item.fromValue
          }
          if (user.value.incomeTax && item.id == user.value.incomeTax.id) {
            selectedIncomeTaxPercentage.value = showLabel
            incomeTaxRangeId.value = item.id
          }
          incomeTaxPercentages.value.push({ label: showLabel, value: item.id })
        })
      }
    }

    onMounted(async () => {
      await store.dispatch(taxSettingsActionTypes.GET_TAXSETTINGS, { year: new Date().getFullYear() })
      getIncometaxPercentage()
    })
    watch(
      () => _.cloneDeep(locale.value),
      () => {
        useOfCarOptions.value = [
          { label: t('_generalTerms.yes'), value: true },
          { label: t('_generalTerms.no'), value: false }
        ]
        getIncometaxPercentage()
      }
    )
    return {
      incomeTaxPercentages,
      selectPercentage,
      updateUserOptions,
      passwordFields,
      updateExistingPassword,
      updateNewPassword,
      updateRepeatNewPassword,
      useOfCarOptions,
      selectUseOfCarOption,
      user,
      loader,
      privateUserMessageVisible,
      taxMessageVisible,
      updatePassword,
      passwordErrors,
      updatePasswordErrors,
      closePopup,
      showPopup,
      adminClientRole,
      selectedIncomeTaxPercentage,
      privateUse,
      incomeTaxRangeId,
      saveButtonStatus
    }
  },
  components: {
    InputElement,
    Button,
    Dropdown,
    Loader,
    InfoPopup
  }
})
