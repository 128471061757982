
import { defineComponent, ref } from 'vue'
import AccountDetails from '@/components/UserProfile/AccountDetails.vue'
import UserCarPolicy from '@/components/UserProfile/UserCarPolicy.vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'UserProfile',
  setup() {
    const route = useRoute()
    const activeTab = route.params.tab ? ref(route.params.tab) : ref('accountDetails')
    const setTab = (currentTab) => {
      activeTab.value = currentTab
    }
    const isSelected = (currentTab) => {
      return activeTab.value == currentTab
    }
    return { activeTab, setTab, isSelected }
  },
  components: {
    AccountDetails,
    UserCarPolicy
  }
})
