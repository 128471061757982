
import { defineComponent, onMounted, computed } from 'vue'
import { useStore } from '@/store'
import { AuthActionTypes } from '@/store/auth/actions'
import { carPolicyActionTypes } from '@/store/carPolicy/actions'
import getTranslatedBodyType from '@/filters/getTranslatedBodyType'
import getTranslatedFuelType from '@/filters/getTranslatedFuelType'
import { leaseActionTypes } from '@/store/leaseCategories/actions'
import Loader from '@/components/UI/Loader.vue'
import formatCurrency from '@/filters/CurrencyFormat'
import { VueCookieNext } from 'vue-cookie-next'
import ConvertToCommas from '@/filters/ConvertToCommas'
export default defineComponent({
  name: 'Driver Car Policy',
  setup() {
    const store = useStore()
    const user = computed(() => store.state.auth.currentUser)
    const loader = computed(() => store.state.auth.loader)
    const policy = computed(() => store.state.auth.carPolicyByID)
    const leaseCategory = computed(() => store.state.leaseCategories.leaseCategoryByID)
    const initialize = async () => {
      if (VueCookieNext.getCookie('companyId') && VueCookieNext.getCookie('employeeCarPolicyId')) {
        await store.dispatch(AuthActionTypes.GET_CARPOLICY_BY_ID, {
          companyId: VueCookieNext.getCookie('companyId'),
          carPolicyId: VueCookieNext.getCookie('employeeCarPolicyId')
        })
      }
      if (user.value.leaseCategory !== null) {
        await store.dispatch(leaseActionTypes.GET_LEASEBYID, {
          companyId: VueCookieNext.getCookie('companyId'),
          leaseCategoryId: user.value.leaseCategory.id
        })
      }
    }
    const downloadCarPolicyDocument = () => {
      store.dispatch(carPolicyActionTypes.DOWNLOAD_CARPOLICYDOCUMENT, {
        companyId: VueCookieNext.getCookie('companyId'),
        carPolicyId: VueCookieNext.getCookie('employeeCarPolicyId')
      })
    }
    onMounted(async () => {
      await initialize()
    })
    return {
      user,
      loader,
      policy,
      leaseCategory,
      getTranslatedFuelType,
      getTranslatedBodyType,
      formatCurrency,
      downloadCarPolicyDocument,
      ConvertToCommas
    }
  },
  components: {
    Loader
  }
})
